<template>
	<div class="app">
		<header>
			<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

			<p class="title">{{ product.name }}</p>
		</header>

		<img :src="img" :alt="product.name" class="product-image">

		<div class="container">
			<div class="box mb-2 mt-3">
				<div class="header mb-4">
					<h4 class="price" v-if="formData.subtotal">Rp. {{ Number(formData.subtotal).toLocaleString() }}</h4>
					<h4 class="price" v-if="!formData.subtotal && product.min == product.max">Rp. {{ Number(product.min).toLocaleString() }}</h4>
					<h4 class="price" v-if="!formData.subtotal && product.min != product.max">Rp. {{ Number(product.min).toLocaleString() + ' - ' + Number(product.max).toLocaleString() }}</h4>

					<p class="product-title">{{ product.name }}</p>
				</div>

				<div class="break-primary mb-3"></div>

				<div class="variant" v-for="variant in product.variants" :key="`variant-${variant.id}`">
					<h6 class="title">{{ variant.name }}: <p class="gray">{{ formData.variants[variant.name] }}</p></h6>
					
					<div class="radios">
						<div class="radio-group" v-for="option in variant.options" :key="`variant-${option.id}`">
							<input type="radio" :name="variant.name" :id="`variant-${option.id}`" @change="updateProductView(option.image_url, option.price)" :value="option.name" v-model="formData.variants[variant.name]" hidden>
							<label :for="`variant-${option.id}`">{{ option.name }}</label>
						</div>
					</div>
				</div>

				<div class="break mb-2 mt-2" v-if="product.combinations && product.combinations.length > 0"></div>

				<div class="combination" v-for="combination in product.combinations" :key="`combination-${combination.id}`">
					<h6 class="title">{{ combination.name }}: <p class="gray">{{ formData.variants[combination.name] }}</p></h6>
					
					<div class="radios">
						<div class="radio-group" v-for="option in combination.options" :key="`combination-${option.id}`">
							<input type="radio" :name="combination.name" :id="`combination-${option.id}`" @change="updateCombinationPrice(combination.id, option.price)" :value="option.name" v-model="formData.variants[combination.name]" hidden>
							<label :for="`combination-${option.id}`">{{ option.name }}</label>
						</div>
					</div>
				</div>
				
				<div class="break-primary mt-2"></div>

				<div class="main mb-2">
					<h6 class="title">Berat</h6>
					<pre class="information">{{ product.weight }} gram</pre>
				</div>

				<div class="main mb-4">
					<h6 class="title">Deskripsi Produk</h6>
					<pre class="information">{{ product.product_information }}</pre>
				</div>

				<div class="order mb-4">
					<div class="order-group">
						<button type="button" class="button chat-seller" @click="$router.push('/chat')">Chat Seller</button>
						<button type="button" @click="addToCart" class="button add-to-cart">+ Add To Cart</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			token: localStorage.getItem('token'),
			id: this.$route.params.id,
			product: '',
			combination: [],
			variant: 0,
			formData: {
				product_id: this.$route.params.id,
				variants: {},
				total: 0,
				quantity: 1,
				subtotal: 0,
			},
			img: ''
		}
	},
	methods: {
		getProduct() {
			axios.get(`product/${this.id}`)
				.then(res => {
					this.product = res.data;
					this.img = res.data.image_url;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		updateQuantity(val) {
			if (this.formData.quantity + val == 0) return;

			this.formData.quantity += val;
			this.formData.subtotal = this.formData.quantity * this.formData.total;
		},
		updateProductView(image, price) {
			this.img = image;
			this.variant = parseInt(price);
			
			this.updateSubtotal();
		},
		updateCombinationPrice(id, price) {
			this.combination.forEach((val, i) => {
				if (val.id == id) {
					val.price = 0;

					this.updateSubtotal();
					return;
				}
			});

			this.combination.push({id: id, price: parseInt(price)}); 
			this.updateSubtotal();
		},
		updateSubtotal() {
			let combinationPrices = 0;

			this.combination.forEach(val => {
				combinationPrices += val.price;
			});

			this.formData.total = combinationPrices + this.variant;

			this.formData.subtotal = this.formData.quantity * this.formData.total;
		},
		addToCart() {
			if (!this.token) {
				this.$router.push('/login');

				return;
			}

			let valid = 0;
			let validator = 0; 

			if (this.product.combinations.length > 0) valid += this.product.combinations.length;
			if (this.product.variants.length > 0) valid += this.product.variants.length;

			for (let a in this.formData.variants) {
				validator++;
			}

			if (validator == valid) {
				axios.post(`cart?token=${this.token}`, this.formData)
					.then(res => {
						this.$router.push('/cart');
					})
					.catch(err => {
						this.$router.push('/login');
					}); 
			} else {
				alert('Mohon Memilih Semua Varian dan Kombinasi Produk')
			}
		}
	},
	created() {
		this.getProduct();
	}
}
</script>

<style scoped>

header .title {
	font-size: 15px;
}

img {
	object-fit: contain;
}

h6.title {
	font-size: 14px;
	font-weight: bold;
}

p.gray {
	display: inline;
	font-size: 12px;
	font-weight: normal;
	color: gray;
}

.break-primary {
	background-color: rgb(227, 244, 255);
	width: 120%;
	height: 4px;
	margin-left: -10%;
}

.break {
	background-color: rgb(227, 244, 255);
	width: 120%;
	height: 1px;
	margin-left: -10%;
}

.product-image {
	height: 300px;
}

.main {
	padding: 10px 0;
}

pre.information {
	font-size: 13px;
	overflow: auto;
	white-space: pre-wrap;
	font-family: inherit;
	margin: 0;
}

.price {
	font-weight: 600;
}

.radios {
	display: flex;
	flex-wrap: wrap;
}

.radio-group label {
	min-width: 2em;
	padding: 5px 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin: 0 10px 10px 0;
	text-align: center;
	font-size: 13px;
}

input[type=radio]:checked + label {
	border-color: var(--primary2);
}

.order-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.order-group button.add-to-cart, .order-group button.chat-seller {
	width: 48%;
	font-size: 16px;
	padding: 5px 0;
	margin: 0;
	border-radius: 5px;
	margin-bottom: .5rem;
}

button.chat-seller {
	background-color: white;
	color: var(--primary);
}

button.add-to-cart {
	color: white;
}

.order-form {
	margin: 0;
	height: 100%;
	display: flex;
}

.order-form button {
	background-color: var(--primary);
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	color: white;
}

.order-form h6 {
	margin: 0 1rem;
	line-height: 40px;
}

.container {
	min-height: fit-content;
}

</style>